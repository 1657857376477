import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { MetaData, NgEventBus } from 'ng-event-bus';
import { SfAnalyticsService } from '../logsandtags/sf-analytics.service';
import { RouteParamsService } from 'src/app/routing/service/route-params.service';
import { ConfirmationService } from 'src/app/external/service/confirmation.service';

@Component({
    selector: 'app-analytics-tag-listener',
    template: ``,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnalyticsTagListenerComponent implements OnInit {

    // Listen for all Analytics events
    eb = inject(NgEventBus)
    config = {
        tagPrefix: "FM:Tags:Analytics",
        target: "**"
    }

    sfa = inject(SfAnalyticsService)
    routeParamsService = inject(RouteParamsService)
    confirmationService = inject(ConfirmationService)

    ngOnInit(): void {
        this.listen().subscribe((meta: MetaData) => {
            const screenId = meta.data.screenId
            const claimId = this.routeParamsService.getClaimId()
            const clientId = this.confirmationService.getClientId();

            if (clientId == '') {
                this.confirmationService.getClientIdObservable().subscribe({
                    next: (data) => {
                        this.sfa.setValues({
                            externalClaimId: claimId,
                            externalClientId: data.clientId
                        })
                        this.sfa.sendData(screenId ? screenId : "not set")
                    }, error: (error) => {
                        console.log(error)
                    }
                })
            } else {
                this.sfa.setValues({
                    externalClaimId: claimId,
                    externalClientId: clientId
                })
                this.sfa.sendData(screenId ? screenId : "not set")
            }
        })
    }

    listen() {
        return this.eb.on(`${this.config.tagPrefix}:${this.config.target}`)
    }
}
