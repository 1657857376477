import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SHARED_COMPONENTS } from './components'
import { SHARED_DIRECTIVES } from './directives/shared-directives';
import { AnalyticsTagListenerComponent } from './services/analytics/analytics-tag-listener/analytics-tag-listener.component';

@NgModule({
    declarations: [
        ...SHARED_COMPONENTS,
        ...SHARED_DIRECTIVES,
        AnalyticsTagListenerComponent
    ],
    imports: [CommonModule],
    exports: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, AnalyticsTagListenerComponent]
})
export class SharedModule { }
