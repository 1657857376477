import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ValidatorService } from './validator.service';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor(
    private sanitizer: DomSanitizer,
    private validatorService: ValidatorService
  ) { }

  public getCookieValue(property: string): any {
    const docCookie = this.sanitizer.sanitize(SecurityContext.HTML, document.cookie);
    if (docCookie !== null) {
      const cookies = docCookie.split(';');
      for (const cookie of cookies) {
        if (cookie.indexOf(property) >= 0) {
          const something = cookie.trim().substring(property.length, cookie.length);
          if (this.validatorService.checkNotEmpty(something)) {
            return something;
          } else {
            return '';
          }
        }
      }
    }
    return '';
  }

  public getOktaJWT(): any {
    const token = this.getCookieValue('sf-cauth1=');
    return token !== '' ? 'Bearer ' + token : ''
  }
}
