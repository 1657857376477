import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError, Observable, of, switchMap } from 'rxjs'
import { CacheService } from 'src/app/shared/services/helper/cache.service'
import { UrlService } from '../../../shared/services/helper/url.service'
import { VehicleDetails } from '../../models/app-models/vehicleDetails.model'
import { CardInput } from '../../models/select-card.model'

@Injectable({
  providedIn: 'root'
})
export class VehicleDetailsService {
  constructor(
    private http: HttpClient,
    private urlService: UrlService,
    private cache: CacheService) { }
  vehicles: CardInput[] = []

  getVehicleInformation(external_claim_id: string): Observable<CardInput[]> {
    const cachedVehicles = this.cache.getSession("vehicleDetails");
    if (this.vehicles.length > 0) {
      return of(this.vehicles);
    } else if (cachedVehicles) {
      this.vehicles = cachedVehicles;
      return of(cachedVehicles);
    } else {
      let vehicleDetailsUrl = this.urlService.getServiceUrl('vehicleDetails');
      vehicleDetailsUrl = vehicleDetailsUrl + "?external_claim_id=" + external_claim_id + "";
      return this.http.get(vehicleDetailsUrl).pipe(
        switchMap((response: any) => {
          this.vehicles = this.formatVehicleDetails(response);
          this.cache.setSession("vehicleDetails", this.vehicles);
          return of(this.vehicles);
        }),
        catchError((err) => {
          throw Error(err)
        })
      )
    }
  }

  formatVehicleDetails(res: Array<VehicleDetails>): CardInput[] {
    let tempCardInfo: CardInput = {
      id: '',
      body: [],
      title: '',
    };
    res.forEach((eachCard: any) => {
      tempCardInfo.title = eachCard.year + " " + eachCard.make + " " + eachCard.model;
      tempCardInfo.id = "Id_" + eachCard.id;
      tempCardInfo.body.push({
        "line": "VIN :" + eachCard.vin
      });
    });
    return [tempCardInfo];
  }

  removeVehicleDetails(): void {
    this.vehicles = [];
    this.cache.removeSession("vehicleDetails");
  }
}
