import { ChangeDetectorRef, Component } from '@angular/core';
import { exchangeOauth2Tokens } from 'customer-oauth2-token-management-utility';
import { CookieService } from 'ngx-cookie-service';
import { Observable, from } from 'rxjs';
import { CacheService } from '../../services/helper/cache.service';
import { UrlService } from '../../services/helper/url.service';

@Component({
  selector: 'app-claimshub-modal-timeout',
  templateUrl: './modal-timeout.component.html',
  styleUrls: ['./modal-timeout.component.scss'],
})
export class ModalTimeoutComponent {
  jwtExpireTime: number = 0;
  modalTimer: any;
  logoutTimer: any;
  manualtoggle: boolean = false;

  constructor(
    public ref: ChangeDetectorRef,
    private cookieService: CookieService,
    private urlService: UrlService,
    private cacheService: CacheService,
  ) {
    this.setTimeoutModalTrigger();
  }

  setTimeoutModalTrigger() {
    // Clear existing timers if they exist
    if (this.modalTimer) {
      clearTimeout(this.modalTimer);
    }
    if (this.logoutTimer) {
      clearTimeout(this.logoutTimer);
    }

    const jwtPayload = this.getParsedJWTCookie();
    if (jwtPayload) {
      // Convert JWT Expiry Time, seconds since epoch, to milliseconds since epoch
      this.jwtExpireTime = jwtPayload.exp * 1000;

      const timeToExpiry = this.jwtExpireTime - Date.now();
      if (timeToExpiry < 0) {
        // JWT has already expired
        return;
      }
      this.logoutTimer = setTimeout(() => {
        // No action, logout
        this.logout();
      }, timeToExpiry);

      const timeToModal = timeToExpiry - 120000;
      if (timeToModal < 0) {
        // JWT is already within the 2 minutes to expiry
        this.modalTimer = setTimeout(this.showTimeoutModal.bind(this), 2000);
        return;
      }
      this.modalTimer = setTimeout(() => {
        // Pop modal
        this.showTimeoutModal();
      }, timeToModal);
    } else {
      // JWT is not present or parseable, fallback to 15 minutes
      this.jwtExpireTime = Date.now() + 15 * 60 * 1000; // 15 minutes

      const timeToExpiry = this.jwtExpireTime - Date.now();
      this.logoutTimer = setTimeout(() => {
        // No action, logout
        this.logout();
      }, timeToExpiry);

      const timeToModal = timeToExpiry - 120000;
      this.modalTimer = setTimeout(() => {
        // Pop modal
        this.showTimeoutModal();
      }, timeToModal);
    }
  }

  extendSession() {
    let refreshed = false;

    // JWT Refresh
    if (this.cookieService.check('sf-cauth1')) {
      this.refreshJWT();
      refreshed = true;
    }

    if (!refreshed) {
      this.logout();
      return;
    }

    this.hideTimeoutModal();
  }

  logout() {
    // CookieService 'Delete' clears the cookies when function is invoked.
    this.cookieService.delete('sf-cauth1', '/', '.statefarm.com', true); // jwt - loa4 auth
    this.cookieService.delete('sf-cauth2', '/', '.statefarm.com', true); // jwt - loa4 refresh
    this.cookieService.delete('sfuid', '/', '.statefarm.com', true);
    this.cookieService.delete('enterprise_interaction_identifier', '/', '.statefarm.com', true);
    this.cacheService.deleteAllCache()
    this.cacheService.removeAllSession()
    this.cacheService.removeAllLocal()

    this.redirectToLogOut()
  }

  redirectToLogOut(): void {
    let logout = this.urlService.getServiceUrl('proofing');
    logout = logout + '/login-interceptor/logout';
    window.location.assign(logout);
  }

  hideTimeoutModal(): void {
    this.manualtoggle = false
  }

  showTimeoutModal(): void {
    this.manualtoggle = true
  }

  getMinutes() {
    this.ref.markForCheck();
    return Math.max(0, Math.floor((this.jwtExpireTime - Date.now()) / 1000 / 60));
  }

  getSeconds() {
    this.ref.markForCheck();
    return ('00' + `${Math.max(0, Math.floor(((this.jwtExpireTime - Date.now()) / 1000) % 60))}`).slice(-2);
  }

  refreshJWT(): Observable<any> {
    const obs = from(exchangeOauth2Tokens());
    obs.subscribe(() => this.setTimeoutModalTrigger());
    return obs;
  }

  getParsedJWTCookie() {
    const jwt = this.getJWTCookie();
    if (jwt) {
      let payloadSection = jwt.split('.')?.[1];
      if (payloadSection === undefined) {
        return undefined;
      }
      // Convert Base64URLEncoded back into Base64
      payloadSection = payloadSection.replace(/-/g, '+').replace(/_/g, '/');
      // Decode from Base64, get an iterable, for each char convert to hex encoding, join back into string, and decode to utf-8
      payloadSection = decodeURIComponent(window.atob(payloadSection).split('').map((c) => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`).join(''));
      // Return JSON object
      return JSON.parse(payloadSection);
    } else {
      return undefined;
    }
  }

  public getJWTCookie(bearer = false) {
    const jwt = this.cookieService.get('sf-cauth1')
      ? this.cookieService.get('sf-cauth1') : undefined;
    if (jwt && bearer) {
      return `Bearer ${jwt}`;
    } else {
      return jwt;
    }
  }
}
