import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { RouteParamsService } from 'src/app/routing/service/route-params.service';
import { Dol } from 'src/app/shared/models/dol.model';
import { LongDatePipe } from 'src/app/shared/pipes/long-date.pipe';
import { DolService } from 'src/app/shared/services/global/dol.service';
import { ParticipantName } from '../../../shared/models/participantName.model';
import { ParticipantNameService } from '../../../shared/services/global/participant-name.service';
import { NgEventBus } from 'ng-event-bus';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  providers: [LongDatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LandingComponent implements OnInit {
  adobeScreenId: string = "landing-page"
  loginDestinationUrl: string = '';

  name$!: Observable<ParticipantName>
  dol$!: Observable<Dol>;

  routeParamsService = inject(RouteParamsService)
  dolService = inject(DolService)
  eb = inject(NgEventBus)
  participantNameService = inject(ParticipantNameService)

  ngOnInit(): void {
    const claimId = this.routeParamsService.getClaimId();
    const participantId = this.routeParamsService.getParticipantId();

    this.eb.cast("FM:Tags:Analytics:landing", { screenId: this.adobeScreenId })

    this.name$ = this.participantNameService.participantName(claimId, participantId);
    this.dol$ = this.dolService.DOL(claimId, participantId);
  }

  RedirectToformsPage(): void {
    this.routeParamsService.navigate('forms/s-1');
  }
}
