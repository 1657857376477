import { Inject, NgModule } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { LOGGER, Logger } from '../observablility/logger/token';
import { routes } from './routes';

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(
    private readonly router: Router,
    @Inject(LOGGER) private logger: Logger
  ) {
    this.router.events.subscribe((event) => {
    })
  }
}
