import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { AuthenticationService } from '../helper/authentication.service';
import { UrlService } from 'src/app/shared/services/helper/url.service';
import { ValidatorService } from 'src/app/shared/services/helper/validator.service';
import { ParticipantName } from '../../models/participantName.model';

@Injectable({
  providedIn: 'root'
})
export class ParticipantNameService {

  participantname: ParticipantName = new ParticipantName();

  constructor(
    private http: HttpClient,
    private urlService: UrlService,
    private validatorService: ValidatorService,
    private authenticationService: AuthenticationService,
  ) { }

  participantName(external_claim_id: string, external_participant_id: string): Observable<ParticipantName> {
    if (this.validatorService.checkEmpty(this.participantname.firstname) || this.validatorService.checkEmpty(this.participantname.lastname)) {
      let participantnameurl = this.urlService.getServiceUrl('participantsname');
      participantnameurl = participantnameurl + "?external_claim_id=" + external_claim_id + "&external_participant_id=" + external_participant_id + "";

      return this.http.get(participantnameurl).pipe(
        switchMap((response: any) => {
          this.participantname.firstname = response.firstname;
          this.participantname.lastname = response.lastname;
          return of(this.participantname);
        }), catchError((err: any) => {
          console.log(err)
          this.authenticationService.handleRoute(err);
          throw Error(err.message)
        })
      )
    } else {
      return of(this.participantname);
    }
  }
}
