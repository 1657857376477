import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CORE_COMPONENTS } from './components/components';

@NgModule({
  declarations: [
    ...CORE_COMPONENTS
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  exports: [
    ...CORE_COMPONENTS,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ]
})

export class CoreModule {}
// export class CoreModule extends EnsureModuleLoadedOnceGuard {
//   constructor(@Optional() @SkipSelf() parentModule: CoreModule) { //Checks to make sure Core is only loaded in App Module
//     super(parentModule);
//   }
// }
// This is the Core Module for our DQFC Application
// Reusable
//   Components, pipes, directives
// Singleton Services
//   Ex. Logging Service, Error Service, DataService


// export class EnsureModuleLoadedOnceGuard {
//   constructor(targetModule: any) {
//     if (targetModule) {
//       throw new Error(`${targetModule.constructor.name} has already been loaded! 
//         Import this module in the AppModule only.`);
//     }
//   }
  
// }